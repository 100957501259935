@import "~bootstrap/scss/bootstrap";
@import "./navBar.scss";

.background {
  background: linear-gradient(0deg, grey 0%, rgba(255, 217, 0, 0.514) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

section {
  display: flex;
  flex-direction: column;
  font-family: 'Play', sans-serif;
  color: black;
  h1 {
    margin-top: 20px;
    text-align: center;
  }
  .content {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  .carousel {
    border: 1px solid rgba(128, 128, 128, 0.466);
  }
  #packages {
    margin-top: 20px;
  }
  #gallery {
    margin-top: 20px;
  }
  #contact {
    margin-top: 20px;
  }
  div {
    .title {
      margin-top: 5vh;
      margin-bottom: 20px;
    }
  }
}
#contact {
  margin-bottom: 100px;
}
a {
  color: rgb(41, 41, 41);
  text-decoration: none;
}
.accordion-item {
  background-color: rgba(0, 0, 0, 0.080);
}
.accordion-button {
  background-color: rgba(0, 0, 0, 0.080) !important;
  color: black !important;
}
#phone {
  margin-left: 5px;
}
#contact {
  display: flex;
  justify-content: center;
}
.disclaimer{
  margin-top: 5px;
  // font-size: large;
  font-style: italic;
}
