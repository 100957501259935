.navbar {
  display: flex;
  background-color: black;
  img {
    max-width: 50px;
  }
  a {
    color: gold;
    margin: 10px;
    &:hover{
      transform: scale(105%);
    }
  }
  .nav-item {
    margin-bottom: 3px;
    a{
      text-decoration: none;
    }
  }
  .navbar-collapse {
    .btn {
      color: gold;
      margin-right: 10px;
      margin-top: 5px;
      &:hover{
        transform: scale(105%);
      }
    }
    span {
      cursor: pointer;
    }
  }
  .navbar-toggler {
    border-color: gold;
  }
  .navbar-toggler-icon {
    background-image: url('../assets/golden-hamburger-icon.svg.png');
  }
  #name {
    color: gold;
  }
  @media only screen and (min-width: 575px) {
    #name {
      color: gold;
      margin-left: 36%;
    }
  }
}